<template>
  <div class="new-password">
    <div v-if="getRecoveryToeknStatus">
      <div class="instructions">
        {{ $t('views.new_password.instructions') }}
      </div>
      <validation-observer>
        <edudash-input
          v-model="password"
          prepend-icon="lock"
          type="password"
          rules="required|password:@confirmation"
          :name="$t('views.new_password.password')"
          :label="$t('views.new_password.password_label')"
        />
        <edudash-input
          v-model="passwordConfirmation"
          prepend-icon="lock"
          vid="confirmation"
          type="password"
          :name="$t('views.new_password.password_confirmation')"
          :label="$t('views.new_password.password_confirmation_label')"
        />
      </validation-observer>
      <v-row>
        <v-col cols="6">
          <edudash-button
            :text="$t('views.new_password.send')"
            :full-width="true"
            @click="changePassword"
          />
        </v-col>
      </v-row>

      <dialog-box
        :title="$t('views.new_password.dialog.title')"
        :text="$t('views.new_password.dialog.text')"
        :btn-text="$t('views.new_password.dialog.confirm')"
        :show="confirmationDialog"
        @confirm="closeDialog"
      />
    </div>
    <div
      v-else
      class="expired"
    >
      {{ $t('views.new_password.expired') }}
    </div>
  </div>
</template>

<script>
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';
import DialogBox from '@/components/feedbacks/DialogBox.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RecoverPassword',
  components: {
    EdudashButton,
    EdudashInput,
    DialogBox,
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      confirmationDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getRecoverSent',
      'getRecoveryToeknStatus',
    ]),
  },
  watch: {
    getRecoverSent(value) {
      if (value) {
        this.confirmationDialog = true;
        this.changeRecoverSent();
      }
    },
  },
  mounted() {
    this.changeToolbarText(this.$t('views.password_recover.title'));
    this.validateRecoveryToken({
      resource_type: 'teacher',
      token: this.$route.params.token,
      email: this.$route.params.email,
    });
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'newPassword',
      'changeRecoverSent',
      'validateRecoveryToken',
    ]),
    changePassword() {
      this.newPassword({
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        resource_type: 'teacher',
        token: this.$route.params.token,
        email: this.$route.params.email,
      });
    },
    closeDialog() {
      this.confirmationDialog = false;
    },
  },
};
</script>

<style lang="scss">
.new-password {
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;

  .instructions {
    margin-bottom: 32px;
    @include font-open-sans-semi-bold;
    font-size: $font-size-base;
  }
  .expired {
    text-align: center;
  }
}
</style>
